import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

export const PASSWORD_LINK_GENERATOR = 'lovelovelove';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Karolin Berliana';
export const GIRL_NAME_SHORT = 'Karolin';
export const GIRL_FATHER_NAME = `Yuyu Gunawan`;
export const GIRL_MOTHER_NAME = `Erna Lim`;
export const GIRL_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `<b>The daughter of</b><br /> Mr. ${GIRL_FATHER_NAME} <br /> and Mrs. ${GIRL_MOTHER_NAME}`
    : `<b>Putri dari</b> <br />Bapak ${GIRL_FATHER_NAME} <br /> dan Ibu ${GIRL_MOTHER_NAME}`;

// -> boy section
export const BOY_NAME = 'Randy Harpikto';
export const BOY_NAME_SHORT = 'Randy';
export const BOY_FATHER_NAME = `Herry Widjaja Tjemerlang (†)`;
export const BOY_MOTHER_NAME = `Teh Pik Cing`;
export const BOY_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `<b>The son of</b><br /> Mr. ${BOY_FATHER_NAME} <br /> and Mrs. ${BOY_MOTHER_NAME}`
    : `<b>Putra dari</b><br /> Bapak ${BOY_FATHER_NAME}<br /> dan Ibu ${BOY_MOTHER_NAME}`;

// -> bride section
export const BRIDE_HASHTAG = `#RANDYfeeLINlove`;
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'randy_anfieldboy';
export const IG_GIRL = 'karolinberliana';
export const IG_FILTER_URL = `https://www.instagram.com/ar/376318704910736/`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/SEO_ZM_NDBEgE.jpg?updatedAt=1703438906140`;
export const SEO_URL = 'https://invitato.net/RandyKarolin';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1704625200;
export const EPOCH_RESEPSI_START = 1704625200;
export const EPOCH_END_EVENT = 1704636000;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2023-12-03');
export const DATE_RESEPSI = new Date('2024-01-07');

export const WEDDING_AKAD_TIME = '16.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Vihara Dharma Ratna';
export const WEDDING_AKAD_LOC_ROAD = `Jl. Husein Sastranegara No.77, RT.004/RW.004, Jurumudi, Kec. Benda, Kota Tangerang, Banten`;
export const WEDDING_AKAD_DRESSCODE = ``;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_AKAD_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;

export const WEDDING_RESEPSI_TIME = '18.00 WIB';
export const WEDDING_RESEPSI_LOC_NAME = 'Damai Indah Golf PIK';
export const WEDDING_RESEPSI_LOC_ROAD = `Jalan Marina Raya, Kapuk, Kec. Penjaringan, Jakarta Utara`;
export const WEDDING_RESEPSI_DRESSCODE = `Pastel Color`;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_RESEPSI_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;
export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('DD • MMMM • YYYY');
export const IS_SAME_LOCATION =
  WEDDING_AKAD_LOC_NAME === WEDDING_RESEPSI_LOC_NAME &&
  WEDDING_AKAD_LOC_ROAD === WEDDING_RESEPSI_LOC_ROAD;

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://RandyKarolin.com/';
export const BROADCAST_WEDDING_LOCATION = `Damai Indah Golf PIK`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_AKAD = ``;
export const GOOGLE_MAPS_RESEPSI = `https://maps.app.goo.gl/ShXuyqR2BdEHhKdL7`;
export const GOOGLE_MAPS_ADDRESS = `Damai Indah Golf PIK`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `You’re Still The One ( use cover music travel love , Dave Moffat)`;
export const SOUND_URL = 'https://youtu.be/pivRDMCPIfg?si=1nH3GCWpHjeeCS9M';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/gp9uomY7k-Q';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/gp9uomY7k-Q';
export const PREWEDDING_LINK = 'https://youtu.be/gp9uomY7k-Q';
export const PREWEDDING_EMBED = 'https://www.youtube.com/embed/gp9uomY7k-Q';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const INVITATO_URL = 'https://invitato.id';
